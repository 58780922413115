import { AxiosResponse } from 'axios';
import { CompaniesLocationInterface } from 'interface/UserInterface';

import { axiosCooperativeServiceInstance } from '../core/axios/axios.cooperativeServiceConfig';

export const getCompaniesLocation = async ({
  companyId,
  params
}: {
  companyId: string | undefined;
  params?: { perPage: number; sortBy?: string; sort?: string };
}): Promise<APIResponse<CompaniesLocationInterface>> => {
  return axiosCooperativeServiceInstance
    .get(`/companies/location/${companyId}`, {
      params
    })
    .then((res: AxiosResponse<APIResponse<CompaniesLocationInterface>>) => res)
    .catch((err) => err);
};
