import { getUserLogout } from '@api/GET_Logout';
import { getUserMe } from '@api/GET_Me';
import { postUserLogin } from '@api/POST_Login';
import { useI18n } from '@core/hooks/useI18n';
import { appCookies } from '@core/utils/appCookies';
import { isAxiosError } from '@core/utils/errorUtils';
import { localStorageService } from '@core/utils/localStorage';
import { syncRefreshToken } from '@core/utils/shouldRefreshToken';
import { LoadingIndicatorBox } from '@uangcermat/uikit-web';
import { epochFormatter } from '@utils/epochTimeFormatter';
import {
  DataMeInterface,
  LanguageType,
  PostBodyLoginInterface,
  UserCompaniesInterface
} from 'interface/UserInterface';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useMutation, useQuery } from 'react-query';

interface AuthContextInterface {
  isAuthenticated: boolean;
  isLoading: boolean;
  userAuth: DataMeInterface | null;
  errorMessage: string | null;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  login: (value: PostBodyLoginInterface) => void;
  logout: () => void;
  refetchMe: () => void;
  setUserAuth: (value: DataMeInterface) => void;
  setErrorMessage: (message: null | string) => void;
  activeCompany: UserCompaniesInterface | undefined;
  setActiveCompany: (company: UserCompaniesInterface) => void;
  redirectAfterLogin: string | null;
}

export const AuthContext = createContext<AuthContextInterface>({
  isAuthenticated: false,
  isLoading: true,
  userAuth: null,
  errorMessage: null,
  setIsLoading: () => undefined,
  login: () => undefined,
  logout: () => undefined,
  refetchMe: () => undefined,
  setUserAuth: () => undefined,
  setErrorMessage: () => undefined,
  activeCompany: undefined,
  setActiveCompany: () => undefined,
  redirectAfterLogin: null
});

const isRedirectedToDashboard = (pathname: string): boolean => {
  return ['/', '/login', '/404'].includes(pathname);
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [userAuth, setUserAuth] = useState<DataMeInterface | null>(null);
  const [activeCompany, setActiveCompany] = useState<UserCompaniesInterface>();
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [redirectAfterLogin, setRedirectAfterLogin] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { setCookie, removeCookie } = appCookies();
  const router = useRouter();
  const { changeLanguage } = useI18n();

  const { refetch: refetchUserData } = useQuery<APIResponse<DataMeInterface>>('/me', getUserMe, {
    enabled: false,
    onSuccess: ({ data, error }) => {
      setIsLoading(false);

      if (!error) {
        setRedirectAfterLogin(data.redirectAfterLogin);
        const activeCompanyLocalStorage: UserCompaniesInterface =
          typeof localStorageService.getActiveCompany('active_company') === 'string' &&
          JSON.parse(localStorageService.getActiveCompany('active_company') || '');

        setUserAuth(data);
        setActiveCompany(activeCompanyLocalStorage);
        changeLanguage(data.lang);
        if (isRedirectedToDashboard(router.pathname)) {
          if (activeCompanyLocalStorage) {
            router.push('/dashboard/payroll');
          } else {
            if (data.companies.length === 1) {
              setActiveCompany(data.companies[0]);
              localStorageService.setActiveCompany({
                key: 'active_company',
                value: JSON.stringify(data.companies[0])
              });
              router.push(data.redirectAfterLogin);
            } else {
              router.push('/dashboard/onboardingcompany');
            }
          }
        }
      }
    }
  });

  const { data: logoutData, refetch: refetchLogout } = useQuery('/logout', getUserLogout, {
    enabled: false,
    onSuccess: () => {
      setIsLoading(false);
      try {
        if (!logoutData?.error) {
          removeCookie({
            name: 'access_token'
          });
          removeCookie({
            name: 'refresh_token'
          });
          setUserAuth(null);
          setActiveCompany(undefined);
          localStorageService.clearActiveCompany('active_company');
          router.push('/login');
        } else {
          alert(logoutData.message);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (isAxiosError(error)) {
          alert(error?.message);
        }
      }
    }
  });

  const { mutateAsync: mutateAsyncLogin } = useMutation('/login', postUserLogin, {
    onSuccess: () => {
      setIsLoading(false);
    }
  });

  const fetchUserProfile = useCallback(async () => {
    const token = Cookies.get('access_token');
    if (!userAuth && token) {
      setIsLoading(true);
      await refetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, userAuth]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies.get('access_token');
      const refreshToken = Cookies.get('refresh_token');
      const { type } = router.query;
      if (token) {
        if (!userAuth) {
          fetchUserProfile();
        }
      } else {
        if (refreshToken) {
          if (type !== 'autologin') {
            syncRefreshToken().finally(() => {
              fetchUserProfile();
            });
          }
        } else {
          setIsLoading(false);
          if (router.pathname !== '/login') {
            localStorageService.clearActiveCompany('active_company');
            router.push('/login');
          }
        }
      }
    }
    loadUserFromCookies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    setIsLoading(true);
    refetchLogout();
  };

  const handleRefetchMe = () => {
    const token = Cookies.get('access_token');
    if (token) {
      setIsLoading(true);
      refetchUserData();
    }
  };

  const login = async ({
    email,
    password,
    lang
  }: {
    email: string;
    password: string;
    lang: LanguageType;
  }) => {
    try {
      setIsLoading(true);
      const { error, access_token, refresh_token, expire_token, message } = await mutateAsyncLogin({
        email,
        password,
        lang
      });
      if (!error && access_token && !userAuth) {
        setCookie({
          name: 'access_token',
          value: access_token,
          options: {
            expires: expire_token ? epochFormatter(expire_token) : undefined
          }
        });
        if (refresh_token) {
          setCookie({
            name: 'refresh_token',
            value: refresh_token
          });
        }
        localStorageService.setToken({
          key: 'expire_token',
          value: expire_token?.toString()
        });
        fetchUserProfile();
      }
      if (error && message) {
        setErrorMessage(message);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (isAxiosError(error)) {
        const errMsg = error.response?.data.message;
        setErrorMessage(errMsg?.email || errMsg?.password || error.message);
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!userAuth,
        userAuth,
        setIsLoading,
        login,
        isLoading,
        logout: handleLogout,
        refetchMe: handleRefetchMe,
        errorMessage,
        setErrorMessage,
        setUserAuth,
        activeCompany,
        setActiveCompany,
        redirectAfterLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

// eslint-disable-next-line react/prop-types
export const ProtectRoute = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const router = useRouter();

  return (
    <>
      {(isLoading || (!isAuthenticated && router.pathname !== '/login')) && (
        <LoadingIndicatorBox color={process.env.LOADING_INDICATOR_COLOR} backdrop />
      )}
      {children}
    </>
  );
};
