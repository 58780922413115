import {
  NotificationItemInterface,
  ParamsNotificationsInterface
} from '@interface/NotificationInterface';
import { AxiosResponse } from 'axios';

import { axiosAuthInstance } from '../core/axios/axios.authConfig';

export const getNotificationList = async ({
  targetUserId,
  subscriber,
  company_id,
  perPage = 6,
  page,
  lang,
  status
}: ParamsNotificationsInterface): Promise<APIResponse<Array<NotificationItemInterface>>> => {
  return axiosAuthInstance
    .get('/notifications', {
      params: {
        targetUserId,
        subscriber,
        company_id,
        perPage,
        page,
        lang,
        status
      }
    })
    .then((res: AxiosResponse<APIResponse<Array<NotificationItemInterface>>>) => res.data)
    .catch((err) => err);
};
