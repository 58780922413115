import { getNotificationList } from '@api/GET_ NotificationList';
import { getNotificationUnreadCounter } from '@api/GET_ NotificationUnreadCount';
import { usePostNotificationMultipleDelete } from '@api/POST_NotificationMultipleDelete';
import { usePostNotificationMultipleMarkAsRead } from '@api/POST_NotificationMultipleMarkAsRead';
import { postNotificationSingleMarkAsRead } from '@api/POST_NotificationSingleMarkAsRead';
import { usePostNotificationUndoDelete } from '@api/POST_NotificationUndoDelete';
import {
  NotificationItemInterface,
  ParamsBulkNotifIdInterface,
  TabsType
} from '@interface/NotificationInterface';
import { LanguageType } from '@interface/UserInterface';
import { useRouter } from 'next/router';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { useAuth } from './AuthContext';

interface NotificationContextInterface {
  isLoading: boolean;
  activeTab: TabsType;
  isShowNotification: boolean;
  isShowPopupNotification: boolean;
  isViewMore: boolean;
  isEmpty: boolean;
  isModify: boolean;
  totalUnread: number;
  currentPage: number;
  lastPage: number;
  notifData: Array<NotificationItemInterface>;
  selectedItem: Array<string>;
  isSelectAll: boolean;
  isDelete: boolean;
  isShowUndoDelete: boolean;
  redirectUrl: string;
  activeBranchId: string;
  setActiveBranchId: Dispatch<SetStateAction<string>>;
  setRedirectUrl: Dispatch<SetStateAction<string>>;
  setIsDelete: Dispatch<SetStateAction<boolean>>;
  setIsSelectAll: Dispatch<SetStateAction<boolean>>;
  setIsShowNotification: Dispatch<SetStateAction<boolean>>;
  setIsShowPopupNotification: Dispatch<SetStateAction<boolean>>;
  setIsModify: Dispatch<SetStateAction<boolean>>;
  setActiveTab: Dispatch<SetStateAction<TabsType>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setTotalUnread: Dispatch<SetStateAction<number>>;
  fetchCounter: () => void;
  fetchList: () => void;
  fetchMoreData: () => void;
  fetchSelectedItem: () => void;
  resetSelectedItem: () => void;
  onDeleteConfirmation: () => void;
  onBulkMarkAsRead: () => void;
  onUndoDelete: () => void;
  clickDetailItem: (id: string, url?: string) => void;
}

export const NotificationContext = createContext<NotificationContextInterface>({
  isLoading: false,
  isShowNotification: false,
  isShowPopupNotification: false,
  isEmpty: true,
  totalUnread: 0,
  currentPage: 1,
  lastPage: 1,
  notifData: [],
  activeTab: 'all',
  isViewMore: false,
  isModify: false,
  selectedItem: [],
  isSelectAll: false,
  isDelete: false,
  isShowUndoDelete: false,
  redirectUrl: '',
  activeBranchId: '',
  setActiveBranchId: () => undefined,
  setRedirectUrl: () => undefined,
  setIsDelete: () => undefined,
  setIsSelectAll: () => undefined,
  setIsShowNotification: () => undefined,
  setIsShowPopupNotification: () => undefined,
  setIsModify: () => undefined,
  setActiveTab: () => undefined,
  setCurrentPage: () => undefined,
  setTotalUnread: () => undefined,
  fetchCounter: () => undefined,
  fetchList: () => undefined,
  fetchMoreData: () => undefined,
  fetchSelectedItem: () => undefined,
  clickDetailItem: () => undefined,
  onDeleteConfirmation: () => undefined,
  resetSelectedItem: () => undefined,
  onBulkMarkAsRead: () => undefined,
  onUndoDelete: () => undefined
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const { userAuth, activeCompany, setActiveCompany } = useAuth();
  const router = useRouter();
  const { i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeBranchId, setActiveBranchId] = useState<string>('');
  const [isShowNotification, setIsShowNotification] = useState<boolean>(false);
  const [isShowPopupNotification, setIsShowPopupNotification] = useState<boolean>(false);
  const [isViewMore, setIsViewMore] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string>('');
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isShowUndoDelete, setIsShowUndoDelete] = useState<boolean>(false);
  const [isModify, setIsModify] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [totalUnread, setTotalUnread] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [notifId, setNotifId] = useState<string>('');
  const [activeTab, setActiveTab] = useState<TabsType>('all');
  const [notifData, setNotifData] = useState<Array<NotificationItemInterface>>([]);
  const [isInitialState, setIsInitialState] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] = useState<Array<string>>([]);
  const [paramsIds, setParamsIds] = useState<ParamsBulkNotifIdInterface>({ ids: [] });

  const { refetch: refetchUnreadData } = useQuery(
    ['/notifications/unreadCount'],
    () =>
      getNotificationUnreadCounter({
        subscriber: 'gajicermat',
        targetUserId: userAuth?.id ?? '',
        company_id: activeCompany?.id ?? ''
      }),
    {
      enabled: !!activeCompany?.id && !!userAuth?.id
    }
  );

  const { refetch: refetchSingleMarkAsRead } = useQuery(
    ['/notifications/singleRead'],
    () => postNotificationSingleMarkAsRead({ notifId: notifId }),
    {
      enabled: false
    }
  );

  const { refetch: refetchNotificationList } = useQuery(
    ['/notifications'],
    () =>
      getNotificationList({
        subscriber: 'gajicermat',
        targetUserId: userAuth?.id ?? '',
        company_id: activeCompany?.id ?? '',
        perPage: 6,
        page: currentPage,
        status: activeTab === 'unread' ? 10 : undefined,
        lang: i18n.language as LanguageType
      }),
    {
      enabled: false
    }
  );

  const fetchList = useCallback(async () => {
    setIsLoading(true);
    setIsEmpty(true);
    setIsInitialState(false);
    const { data } = await refetchNotificationList();
    if (data) {
      setNotifData(data?.data && data?.data.length ? data?.data : []);
      setCurrentPage(data?.meta?.current_page ?? 1);
      setLastPage(data?.meta?.last_page ?? 1);
      if (data?.meta) {
        setIsViewMore(data.meta?.current_page < data.meta?.last_page);
      }
      setIsLoading(false);
      setIsEmpty(data?.data && data?.data.length ? false : true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const refetchList = useCallback(async () => {
    setCurrentPage(1);
    setNotifData([]);
    setIsEmpty(true);
    setTimeout(() => {
      fetchCounter();
      fetchList();
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCounter = useCallback(async () => {
    const { data } = await refetchUnreadData();
    setTotalUnread(data?.data?.count ?? 0);
  }, [refetchUnreadData]);

  useEffect(() => {
    if (activeCompany && userAuth && isShowNotification && !isInitialState) {
      fetchList();
    }
  }, [activeTab, fetchList, activeCompany, userAuth, isShowNotification, isInitialState]);

  const fetchSelectedItem = useCallback(() => {
    if (isShowNotification && isModify) {
      if (!isSelectAll) {
        if (notifData && notifData.length) {
          const newSelectedItem: Array<string> = [];
          notifData.map((notif: NotificationItemInterface) => newSelectedItem.push(notif.id));
          setSelectedItem(newSelectedItem);
          setParamsIds({ ids: newSelectedItem });
        }
      } else {
        setSelectedItem([]);
      }
    }
  }, [isModify, isSelectAll, isShowNotification, notifData]);

  const clickDetailItem = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (id: string, url?: string) => {
      if (isModify) {
        if (selectedItem && selectedItem.length) {
          const newSelectedItem: Array<string> = [];
          const checkData = selectedItem.includes(id);
          if (checkData) {
            selectedItem.map((notifId: string) => {
              if (notifId !== id) {
                newSelectedItem.push(notifId);
              }
            });
            setSelectedItem(newSelectedItem);
            setParamsIds({ ids: newSelectedItem });
            setIsSelectAll(false);
          } else {
            setSelectedItem([...selectedItem, id]);
            setParamsIds({ ids: [...selectedItem, id] });
          }
        } else {
          setSelectedItem([id]);
          setParamsIds({ ids: [id] });
        }
      } else {
        setNotifId(id);
        setTimeout(() => {
          refetchSingleMarkAsRead();
        }, 100);
        setTimeout(() => {
          setActiveTab('all');
          refetchList();
          refetchUnreadData();
        }, 500);
        setIsShowPopupNotification(false);
        setCurrentPage(1);
        if (url && url !== undefined) {
          let newModule = '';
          // let detailId = '';
          let branchId = '';
          let companyId = '';
          const newUrl = url.split('/');
          if (newUrl[0]) {
            newModule = newUrl[0];
            if (newModule === 'leave') {
              const detailIds = newUrl[1].split('?');
              const branchIds = detailIds[1].split('&');
              // detailId = branchIds[0].replace('id=', '');
              companyId = branchIds[1].replace('cId=', '');
              branchId = branchIds[2].replace('lId=', '');
            }
          } else {
            newModule = newUrl[1];
            if (newModule === 'leave') {
              const detailIds = newUrl[2].split('?');
              const branchIds = detailIds[1].split('&');
              // detailId = branchIds[0].replace('id=', '');
              companyId = branchIds[1].replace('cId=', '');
              if (branchIds[2]) {
                branchId = branchIds[2].replace('lId=', '');
              }
            }
          }
          if (newModule === 'leave') {
            userAuth?.companies.map((company) => {
              if (company.clientId === companyId) {
                setActiveCompany(company);
              }
            });
            setRedirectUrl('leave.request');
            if (branchId) {
              setActiveBranchId(branchId);
            }

            setTimeout(() => {
              router.push(url.replace('list', 'request'));
            }, 500);
          } else {
            setActiveBranchId(branchId);
            setRedirectUrl('attendance.approve');
            setTimeout(() => {
              router.push(url);
            }, 500);
          }
        }
        // TODO: handle redirect page on click notif item
      }
    },
    [
      isModify,
      refetchList,
      refetchSingleMarkAsRead,
      refetchUnreadData,
      router,
      selectedItem,
      setActiveCompany,
      userAuth?.companies
    ]
  );

  const fetchMoreData = useCallback(async () => {
    setIsLoading(true);
    setIsEmpty(true);
    setIsInitialState(false);
    const { data } = await refetchNotificationList();
    if (data) {
      setNotifData(data?.data ? notifData.concat(data?.data) : notifData);
      setCurrentPage(data?.meta?.current_page ?? 1);
      setLastPage(data?.meta?.last_page ?? 1);
      if (data?.meta) {
        setIsViewMore(data.meta?.current_page < data.meta?.last_page);
      }
      setIsLoading(false);
      setIsEmpty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifData]);

  const resetSelectedItem = useCallback(() => {
    setIsSelectAll(false);
    setSelectedItem([]);
    setParamsIds({ ids: [] });
  }, []);

  const { mutate: mutateMarkAsRead } = usePostNotificationMultipleMarkAsRead({
    onSuccess: () => {
      setIsModify(false);
      setSelectedItem([]);
      setParamsIds({ ids: [] });
      setIsSelectAll(false);
      refetchList();
    }
  });

  const { mutate: mutateDelete } = usePostNotificationMultipleDelete({
    onSuccess: () => {
      setIsModify(false);
      setIsDelete(false);
      setIsSelectAll(false);
      setIsShowUndoDelete(true);
      setTimeout(() => {
        setIsShowUndoDelete(false);
      }, 5000);
      refetchList();
    }
  });

  const { mutate: mutateUndoDelete } = usePostNotificationUndoDelete({
    // eslint-disable-next-line sonarjs/no-identical-functions
    onSuccess: () => {
      setIsShowUndoDelete(false);
      setIsModify(false);
      setSelectedItem([]);
      setParamsIds({ ids: [] });
      setIsSelectAll(false);
      refetchList();
    }
  });

  const onBulkMarkAsRead = useCallback(() => {
    if (selectedItem && selectedItem.length) {
      mutateMarkAsRead(paramsIds);
    }
  }, [mutateMarkAsRead, paramsIds, selectedItem]);

  const onDeleteConfirmation = useCallback(() => {
    if (selectedItem && selectedItem.length) {
      mutateDelete(paramsIds);
    }
  }, [mutateDelete, paramsIds, selectedItem]);

  const onUndoDelete = useCallback(() => {
    if (selectedItem && selectedItem.length) {
      mutateUndoDelete(paramsIds);
    }
  }, [mutateUndoDelete, paramsIds, selectedItem]);

  return (
    <NotificationContext.Provider
      value={{
        activeTab,
        isLoading,
        isShowNotification,
        isEmpty,
        isModify,
        isViewMore,
        totalUnread,
        notifData,
        currentPage,
        lastPage,
        selectedItem,
        isSelectAll,
        isDelete,
        isShowUndoDelete,
        isShowPopupNotification,
        redirectUrl,
        activeBranchId,
        setActiveBranchId,
        setRedirectUrl,
        setIsShowPopupNotification,
        setIsDelete,
        setIsSelectAll,
        setIsModify,
        setActiveTab,
        setCurrentPage,
        setIsShowNotification,
        setTotalUnread,
        fetchCounter,
        fetchList,
        fetchMoreData,
        fetchSelectedItem,
        clickDetailItem,
        resetSelectedItem,
        onDeleteConfirmation,
        onBulkMarkAsRead,
        onUndoDelete
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
