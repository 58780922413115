import { AuthProvider, ProtectRoute } from './AuthContext';
import { NotificationProvider } from './NotificationContext';

const AppContext = ({ children }: { children: React.ReactNode }) => (
  <AuthProvider>
    <NotificationProvider>
      <ProtectRoute>{children}</ProtectRoute>
    </NotificationProvider>
  </AuthProvider>
);
export default AppContext;
