import { NavigationMenuInterface } from '@uangcermat/uikit-web/build/src/blocks/Sidebar/Sidebar.type';

interface CustomChildrenInterface extends NavigationMenuInterface {
  permission: string;
}

interface CustomNavigationInterface extends NavigationMenuInterface {
  permission: string;
  children: Array<CustomChildrenInterface>;
}

const COMPANY_PERMISSION = ['setting.company', 'leave.setting', 'attendance.setting'];
const REPORT_PERMISSION = [
  'workSchedule.report',
  'payrollBpjs.list',
  'payrollPph.list',
  'employee.list',
  'leave.list'
];

export const getGuardedNavigation = ({
  userPermission,
  navConfig
}: {
  userPermission: Array<string>;
  navConfig: Array<CustomNavigationInterface>;
}) => {
  const finalNav: Array<CustomNavigationInterface> = [];
  const userPermissionTmp: Record<string, Array<string>> = { settings: [], report: [] };
  userPermission.map((nav) => {
    const menu = nav.split('.');
    const isValidMenu = ['payroll', 'employee', 'cooperative', 'setting'].includes(menu[0]);
    if (isValidMenu && userPermissionTmp[menu[1]] === undefined) {
      userPermissionTmp[menu[1]] = [`${menu[1]}.${menu[2]}`];
      if (COMPANY_PERMISSION.includes(`${menu[1]}.${menu[2]}`)) {
        userPermissionTmp['settings'] = [...userPermissionTmp['settings'], `${menu[1]}.${menu[2]}`];
      }
      if (REPORT_PERMISSION.includes(`${menu[1]}.${menu[2]}`)) {
        userPermissionTmp['report'] = [...userPermissionTmp['report'], `${menu[1]}.${menu[2]}`];
      }
    } else {
      if (userPermissionTmp[menu[1]]) {
        userPermissionTmp[menu[1]] = [...userPermissionTmp[menu[1]], `${menu[1]}.${menu[2]}`];
        if (COMPANY_PERMISSION.includes(`${menu[1]}.${menu[2]}`)) {
          userPermissionTmp['settings'] = [
            ...userPermissionTmp['settings'],
            `${menu[1]}.${menu[2]}`
          ];
        }
        if (REPORT_PERMISSION.includes(`${menu[1]}.${menu[2]}`)) {
          userPermissionTmp['report'] = [...userPermissionTmp['report'], `${menu[1]}.${menu[2]}`];
        }
      }
    }
  });

  navConfig.map((menu) => {
    if (userPermissionTmp[menu.id]) {
      const sub: Array<CustomChildrenInterface> = [];
      if (menu?.children) {
        menu.children.map((submenu) => {
          const submenuSplit = submenu.id.split('.');
          if (
            userPermissionTmp[submenuSplit[0]]?.includes(submenu.id) ||
            userPermissionTmp[submenuSplit[0]]?.includes(submenu.permission)
          ) {
            sub.push(submenu);
          } else if (
            submenuSplit[0] === 'attendance' &&
            userPermissionTmp['attendanceImport']?.includes(submenu.permission)
            // eslint-disable-next-line sonarjs/no-duplicated-branches
          ) {
            sub.push(submenu);
          }
        });
      }
      if (sub.length) finalNav.push({ ...menu, children: sub });
    }
  });
  return finalNav;
};
