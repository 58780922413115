import { NavigationMenuInterface } from '@uangcermat/uikit-web';
import { useTranslation } from 'react-i18next';

interface CustomChildrenInterface extends NavigationMenuInterface {
  permission: string;
}

interface CustomNavigationInterface extends NavigationMenuInterface {
  permission: string;
  children: Array<CustomChildrenInterface>;
}

export const useNavigationConfig = () => {
  const { t } = useTranslation(['menu']);

  const navigationConfig: Array<CustomNavigationInterface> = [
    {
      id: 'payrollCycle',
      icon: 'payroll',
      iconSize: 'micro',
      title: t('menu:payroll.payroll'),
      permission: 'prefundBalance.list',
      children: [
        {
          id: 'payrollCycle.list',
          permission: 'payrollCycle.list',
          title: t('menu:payroll.inprocess'),
          navLink: '/dashboard/payroll'
        },
        {
          id: 'payrollCycle.approve',
          permission: 'payrollCycle.list',
          title: t('menu:payroll.history'),
          navLink: '/dashboard/payroll/history'
        },
        {
          id: 'prefundBalance.list',
          permission: 'prefundBalance.list',
          title: t('menu:payroll.prefund'),
          navLink: '/dashboard/payroll/prefund'
        }
      ]
    },
    {
      id: 'employee',
      icon: 'usergroup',
      iconSize: 'micro',
      title: t('menu:employees.employees'),
      permission: 'employee.onboard_list',
      children: [
        {
          id: 'employee.onboard_list',
          permission: 'employee.onboard_list',
          title: t('menu:employees.onboarding'),
          navLink: '/dashboard/employee'
        },
        {
          id: 'employee.list',
          permission: 'employee.list',
          title: t('menu:employees.directory'),
          navLink: '/dashboard/employee/directory'
        }
      ]
    },
    {
      id: 'leave',
      icon: 'calendarLeave',
      iconSize: 'micro',
      title: t('menu:leave.leave'),
      // eslint-disable-next-line sonarjs/no-duplicate-string
      permission: 'leave.list',
      children: [
        {
          id: 'leave.request',
          permission: 'leave.list',
          title: t('menu:leave.request'),
          navLink: '/leave/request'
        },
        {
          id: 'leave.history',
          permission: 'leave.list',
          title: t('menu:leave.history'),
          navLink: '/leave/history'
        }
      ]
    },
    {
      id: 'attendance',
      icon: 'calendarcheckin',
      title: t('menu:attendance.attendance'),
      iconSize: 'micro',
      // eslint-disable-next-line sonarjs/no-duplicate-string
      permission: 'attendance.list',
      children: [
        {
          id: 'attendance.list',
          permission: 'attendance.list',
          title: t('menu:attendance.submission'),
          navLink: '/attendance/submission'
        },
        {
          id: 'attendance.import',
          permission: 'attendanceImport.view',
          title: t('menu:attendance.import'),
          navLink: '/attendance/import'
        }
      ]
    },
    {
      id: 'workSchedule',
      icon: 'calendarSchedule',
      iconSize: 'micro',
      title: t('menu:workSchedule.workSchedule'),
      permission: 'workSchedule.view',
      children: [
        {
          id: 'workSchedule.view',
          permission: 'workSchedule.view',
          title: t('menu:workSchedule.workSchedule'),
          navLink: '/work-schedule'
        }
      ]
    },
    {
      id: 'payrollComponent',
      icon: 'expense',
      iconSize: 'extra-small',
      title: t('menu:payComponents.title'),
      // eslint-disable-next-line sonarjs/no-duplicate-string
      permission: 'payrollComponent.list',
      children: [
        {
          id: 'payrollComponent.list',
          permission: 'payrollComponent.list',
          title: t('menu:payComponents.allowance'),
          navLink: '/pay-components/allowance'
        },
        {
          id: 'payrollComponent.deduction',
          permission: 'payrollComponent.list',
          title: t('menu:payComponents.deduction'),
          navLink: '/pay-components/deduction'
        }
      ]
    },
    {
      id: 'report',
      icon: 'document',
      iconSize: 'micro',
      title: t('menu:report.report'),
      permission: 'workSchedule.report',
      children: [
        {
          id: 'report.daily',
          permission: 'workSchedule.report',
          title: t('menu:report.dailyAttendance'),
          navLink: '/report/daily-attendance'
        },
        {
          id: 'report.checkin',
          permission: 'workSchedule.report',
          title: t('menu:report.checkInOut'),
          navLink: '/report/attendance'
        },
        {
          id: 'report.employmentStatus',
          permission: 'employee.list',
          title: t('menu:report.employmentStatus'),
          navLink: '/report/employment-status'
        },
        {
          id: 'report.bpjs',
          permission: 'payrollBpjs.list',
          title: t('menu:report.bpjs'),
          navLink: '/report/bpjs'
        },
        {
          id: 'report.pph',
          permission: 'payrollPph.list',
          title: t('menu:report.pph'),
          navLink: '/report/pph-21'
        },
        {
          id: 'leave.list',
          permission: 'leave.list',
          title: t('menu:report.leave'),
          navLink: '/report/leave'
        }
      ]
    },
    {
      id: 'settings',
      icon: 'setting',
      iconSize: 'micro',
      title: t('menu:settings.settings'),
      permission: 'setting.company',
      children: [
        {
          id: 'setting.company',
          permission: 'setting.company',
          title: t('menu:settings.companyDetails'),
          navLink: '/settings/company'
        },
        {
          id: 'branch.list',
          permission: 'setting.branch.list',
          title: t('menu:settings.branch'),
          navLink: '/settings/branch'
        },
        {
          id: 'notification.list',
          permission: 'notification.list',
          title: t('menu:settings.notification'),
          navLink: '/settings/notification'
        },
        {
          id: 'leave.setting',
          permission: 'leave.setting',
          title: t('menu:settings.leaveSetting'),
          navLink: '/settings/leave'
        },
        {
          id: 'attendance.setting',
          permission: 'attendance.setting',
          title: t('menu:settings.attendanceSettings'),
          navLink: '/settings/attendance'
        },
        {
          id: 'approval.list',
          permission: 'approval.list',
          title: t('menu:settings.approval'),
          navLink: '/settings/approval'
        },
        {
          id: 'companyHoliday.view',
          permission: 'companyHoliday.view',
          title: t('menu:settings.holiday'),
          navLink: '/settings/holiday'
        },
        {
          id: 'overtime.list',
          permission: 'overtime.list',
          title: t('menu:settings.overtime'),
          navLink: '/settings/overtime'
        }
      ]
    }
  ];

  return { navigationConfig };
};
