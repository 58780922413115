import { ParamsBulkNotifIdInterface } from '@interface/NotificationInterface';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

import { axiosAuthInstance } from '../core/axios/axios.authConfig';

export const postNotificationMultipleMarkAsRead = async (
  params: ParamsBulkNotifIdInterface
): Promise<BaseResponseInterface> => {
  const { data } = await axiosAuthInstance.post('/notifications/multipleRead', params);
  return data;
};

export function usePostNotificationMultipleMarkAsRead(
  mutateFn?: UseMutationOptions<
    BaseResponseInterface,
    AxiosError<BaseResponseInterface>,
    ParamsBulkNotifIdInterface
  >
) {
  return useMutation<
    BaseResponseInterface,
    AxiosError<BaseResponseInterface>,
    ParamsBulkNotifIdInterface
  >(postNotificationMultipleMarkAsRead, mutateFn);
}
