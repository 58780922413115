import { localStorageService } from '@core/utils/localStorage';
import {
  EnglishAction,
  EnglishAttendance,
  EnglishAttendancePayroll,
  EnglishAttendanceSubmission,
  EnglishCommon,
  EnglishDirectory,
  EnglishEditProfile,
  EnglishEmployee,
  EnglishLeave,
  EnglishLeaveHistory,
  EnglishLeaveRequest,
  EnglishLoginPage,
  EnglishMenu,
  EnglishOnboarding,
  EnglishPayroll,
  EnglishPayrollComponent,
  EnglishPayrollFeed,
  EnglishPrefund,
  EnglishReportBpjs,
  EnglishReportCheckInOut,
  EnglishReportDailyAttendance,
  EnglishReportEmploymentStatus,
  EnglishReportLeave,
  EnglishReportPph,
  EnglishSettingApproval,
  EnglishSettingHoliday,
  EnglishSettingOvertime,
  EnglishSettingsAttendance,
  EnglishSettingsBranch,
  EnglishSettingsCompany,
  EnglishSettingsLeave,
  EnglishSettingsNotification,
  EnglishWorkSchedule
} from '@i18n/locales/en';
import {
  BahasaAction,
  BahasaAttendance,
  BahasaAttendancePayroll,
  BahasaAttendanceSubmission,
  BahasaCommon,
  BahasaDirectory,
  BahasaEditProfile,
  BahasaEmployee,
  BahasaLeave,
  BahasaLeaveHistory,
  BahasaLeaveRequest,
  BahasaLoginPage,
  BahasaMenu,
  BahasaOnboarding,
  BahasaPayroll,
  BahasaPayrollComponent,
  BahasaPayrollFeed,
  BahasaPrefund,
  BahasaReportBpjs,
  BahasaReportCheckInOut,
  BahasaReportDailyAttendance,
  BahasaReportEmploymentStatus,
  BahasaReportLeave,
  BahasaReportPph,
  BahasaSettingApproval,
  BahasaSettingHoliday,
  BahasaSettingOvertime,
  BahasaSettingsAttendance,
  BahasaSettingsBranch,
  BahasaSettingsCompany,
  BahasaSettingsLeave,
  BahasaSettingsNotification,
  BahasaWorkSchedule
} from '@i18n/locales/id';
import { LanguageType } from '@interface/UserInterface';
// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const useI18n = () => {
  const lng: LanguageType = (localStorageService.getLanguage('i18nextLng') as LanguageType) ?? 'en';

  const changeLanguage = async (to: LanguageType) => {
    if (i18n.isInitialized) {
      i18n.changeLanguage(to);
      localStorageService.setLanguage({ key: 'i18nextLng', value: to });
    }
  };

  const englishResources = {
    leave: EnglishLeave,
    common: EnglishCommon,
    login: EnglishLoginPage,
    menu: EnglishMenu,
    payroll: EnglishPayroll,
    onboarding: EnglishOnboarding,
    prefund: EnglishPrefund,
    directory: EnglishDirectory,
    action: EnglishAction,
    employee: EnglishEmployee,
    attendance: EnglishAttendance,
    attendancePayroll: EnglishAttendancePayroll,
    leaveHistory: EnglishLeaveHistory,
    attendanceSubmission: EnglishAttendanceSubmission,
    payrollComponent: EnglishPayrollComponent,
    payrollFeed: EnglishPayrollFeed,
    workSchedule: EnglishWorkSchedule,
    leaveRequest: EnglishLeaveRequest,
    reportDailyAttendance: EnglishReportDailyAttendance,
    reportBpjs: EnglishReportBpjs,
    reportCheckInOut: EnglishReportCheckInOut,
    reportEmploymentStatus: EnglishReportEmploymentStatus,
    reportPph: EnglishReportPph,
    reportLeave: EnglishReportLeave,
    settingApproval: EnglishSettingApproval,
    settingAttendance: EnglishSettingsAttendance,
    settingCompany: EnglishSettingsCompany,
    settingBranch: EnglishSettingsBranch,
    settingHoliday: EnglishSettingHoliday,
    settingLeave: EnglishSettingsLeave,
    settingOvertime: EnglishSettingOvertime,
    profile: EnglishEditProfile,
    settingNotification: EnglishSettingsNotification
  };

  const bahasaResources = {
    leave: BahasaLeave,
    common: BahasaCommon,
    login: BahasaLoginPage,
    menu: BahasaMenu,
    payroll: BahasaPayroll,
    onboarding: BahasaOnboarding,
    prefund: BahasaPrefund,
    directory: BahasaDirectory,
    action: BahasaAction,
    employee: BahasaEmployee,
    attendance: BahasaAttendance,
    attendancePayroll: BahasaAttendancePayroll,
    leaveHistory: BahasaLeaveHistory,
    attendanceSubmission: BahasaAttendanceSubmission,
    payrollComponent: BahasaPayrollComponent,
    payrollFeed: BahasaPayrollFeed,
    workSchedule: BahasaWorkSchedule,
    leaveRequest: BahasaLeaveRequest,
    reportDailyAttendance: BahasaReportDailyAttendance,
    reportBpjs: BahasaReportBpjs,
    reportCheckInOut: BahasaReportCheckInOut,
    reportEmploymentStatus: BahasaReportEmploymentStatus,
    reportPph: BahasaReportPph,
    reportLeave: BahasaReportLeave,
    settingApproval: BahasaSettingApproval,
    settingAttendance: BahasaSettingsAttendance,
    settingCompany: BahasaSettingsCompany,
    settingBranch: BahasaSettingsBranch,
    settingHoliday: BahasaSettingHoliday,
    settingLeave: BahasaSettingsLeave,
    settingOvertime: BahasaSettingOvertime,
    profile: BahasaEditProfile,
    settingNotification: BahasaSettingsNotification
  };

  const resources = {
    en: englishResources,
    id: bahasaResources
  };

  const config = {
    resources,
    lng,
    ns: [
      'common',
      'login',
      'menu',
      'onboarding',
      'payroll',
      'prefund',
      'directory',
      'action',
      'employee',
      'attendance',
      'attendancePayroll',
      'leaveHistory',
      'attendanceSubmission',
      'leave',
      'payrollFeed',
      'workSchedule',
      'leaveRequest',
      'reportDailyAttendance',
      'reportEmploymentStatus',
      'reportBpjs',
      'reportPph',
      'settingApproval',
      'settingHoliday',
      'settingOvertime',
      'payrollComponent',
      'settingBranch',
      'settingNotification'
    ]
  };
  const init = () => {
    i18n.use(initReactI18next).init(config);
  };

  return { i18n, init, changeLanguage };
};
