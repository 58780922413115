import { ChangeLanguageInterface } from '@interface/UserInterface';
import { AxiosResponse } from 'axios';

import { axiosAuthInstance } from '../core/axios/axios.authConfig';

export const postChangeLanguage = async (
  body: ChangeLanguageInterface
): Promise<BaseResponseInterface> => {
  return axiosAuthInstance
    .post('/users/language', body)
    .then((res: AxiosResponse<BaseResponseInterface>) => res.data)
    .catch((err) => err);
};
