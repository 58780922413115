import { AxiosResponse } from 'axios';

import { axiosAuthInstance } from '../core/axios/axios.authConfig';

export const postNotificationSingleMarkAsRead = async ({
  notifId
}: {
  notifId: string;
}): Promise<BaseResponseInterface> => {
  return axiosAuthInstance
    .get(`/notifications/singleRead/${notifId}`)
    .then((res: AxiosResponse<BaseResponseInterface>) => res.data)
    .catch((err) => err);
};
