import { getCompaniesLocation } from '@api/GET_CompaniesLocation';
import { postChangeLanguage } from '@api/POST_ChangeLanguage';
import { AuthContext, useAuth } from '@context/AuthContext';
import { useNotification } from '@context/NotificationContext';
import { useI18n } from '@core/hooks/useI18n';
import { useNavigationConfig } from '@core/hooks/useNavigationConfig';
import { appCookies } from '@core/utils/appCookies';
import { getGuardedNavigation } from '@core/utils/getGuardedNavigation';
import { localStorageService } from '@core/utils/localStorage';
import { syncRefreshToken } from '@core/utils/shouldRefreshToken';
import { NotificationItemInterface, TabsType } from '@interface/NotificationInterface';
import { BranchInterface, BranchLocationInterface, LanguageType } from '@interface/UserInterface';
import {
  ColorBlue,
  ColorLight,
  ColorRed,
  Navbar,
  NavbarUserDropdown,
  Sidebar,
  useClickOutside
} from '@uangcermat/uikit-web';
import { NotificationGroupInterface } from '@uangcermat/uikit-web/build/src/blocks/Navbar/Navbar.type';
import { dateFormatter } from '@utils/dateFormatter';
import { getInitialNameForFallbackAvatar } from '@utils/getInitialName';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths
} from 'date-fns';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import pkgJson from '../../package.json';

const initialUserData: NavbarUserDropdown = {
  name: '',
  avatarUrl: '',
  isShowAccount: false,
  isHoverable: true,
  onClickProfile: () => undefined,
  onClickLogout: () => undefined
};

interface DashboardLayoutProps {
  children: React.ReactNode;
}

const NavbarContainerStyled = styled.div`
  ul {
    top: 4rem !important;
    z-index: 9;
  }

  > div {
    div[data-test-id='navbarCompany'] {
      margin-left: 16px;
    }
    > div:nth-child(2) > div:first-child {
      display: none;
    }
  }
`;

const LayoutStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
`;

const MainStyled = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
`;

const SidebarContainerStyled = styled.div`
  background-color: white;
  min-width: 208px !important;
  padding-top: 16px !important;
`;

const companyImgUrl = process.env.NAVBAR_LOGO_URL ?? '/images/navbar-logo.svg';
const dashboardRoute = '/dashboard/payroll';

const appData = {
  appName: process.env.APP_LABEL ?? process.env.APP_NAME,
  appVersion: pkgJson.version
};

const isShouldHideSidebar = (pathname: string): boolean => {
  return ['/dashboard/onboardingcompany'].includes(pathname);
};
const sidebarActiveIdMapper: Record<string, string> = {
  '/dashboard/payroll': 'payrollCycle.list',
  '/dashboard/payroll/history': 'payrollCycle.approve',
  '/dashboard/payroll/prefund': 'prefundBalance.list',
  '/dashboard/employee': 'employee.onboard_list',
  '/dashboard/employee/directory': 'employee.list',
  '/leave': 'leave.request',
  '/leave/request': 'leave.request',
  '/leave/history': 'leave.history',
  '/attendance': 'attendance.list',
  '/attendance/submission': 'attendance.list',
  '/attendance/approval': 'attendance.approval',
  '/attendance/import': 'attendance.import',
  '/attendance/history': 'attendance.history',
  '/attendance/payroll-feed': 'attendance.payroll',
  '/work-schedule': 'workSchedule.view',
  '/pay-components/allowance': 'payrollComponent.list',
  '/pay-components/deduction': 'payrollComponent.deduction',
  '/settings': 'setting.compony',
  '/settings/company': 'setting.company',
  '/settings/notification': 'notification.list',
  '/settings/leave': 'leave.setting',
  '/settings/attendance': 'attendance.setting',
  '/report': 'report.daily',
  '/report/daily-attendance': 'report.daily',
  '/report/attendance': 'report.checkin',
  '/report/pph-21': 'report.pph',
  '/report/bpjs': 'report.bpjs',
  '/report/employment-status': 'report.employmentStatus',
  '/report/leave': 'leave.list',
  '/settings/approval': 'approval.list',
  '/settings/holiday': 'companyHoliday.view',
  '/settings/overtime': 'overtime.list',
  '/settings/branch': 'branch.list'
};

const TODAY = new Date();

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function DashboardLayout({ children }: DashboardLayoutProps) {
  const { t, i18n } = useTranslation(['common']);
  const currentLanguage = (localStorageService.getLanguage('i18nextLng') as LanguageType) ?? 'en';
  const lastPathname = sessionStorage.getItem('lastPathname');
  const referrer = sessionStorage.getItem('referrer');

  const { changeLanguage } = useI18n();
  const router = useRouter();
  const { navigationConfig } = useNavigationConfig();
  const [notifList, setNotifList] = useState<Array<NotificationGroupInterface>>([]);

  const { logout, userAuth, setUserAuth, activeCompany, setActiveCompany } = useAuth();
  const {
    activeTab,
    notifData,
    isLoading,
    isShowNotification,
    totalUnread,
    isViewMore,
    isEmpty,
    isModify,
    currentPage,
    isSelectAll,
    selectedItem,
    isDelete,
    isShowUndoDelete,
    isShowPopupNotification,
    redirectUrl,
    activeBranchId,
    setActiveBranchId,
    setRedirectUrl,
    setIsShowPopupNotification,
    onDeleteConfirmation,
    onBulkMarkAsRead,
    onUndoDelete,
    setIsDelete,
    setIsSelectAll,
    setCurrentPage,
    setIsModify,
    setIsShowNotification,
    fetchCounter,
    fetchList,
    fetchMoreData,
    fetchSelectedItem,
    setActiveTab,
    clickDetailItem,
    resetSelectedItem
  } = useNotification();

  const companyBranchSwitcherRef = useRef(null);
  const userRefElm = useRef(null);
  const notificationRefElm = useRef(null);

  const [activeMenuIdSidebar, setActiveMenuIdSidebar] = useState<string>('');
  const [isShowAccount, setIsShowAccount] = useState<boolean>(false);
  const [isShowBranch, setIsShowBranch] = useState<boolean>(false);

  const [isHideSidebar, setIsHideSidebar] = useState(false);

  const [listBranch, setListBranch] = useState<Array<BranchInterface>>([]);
  const [activeBranch, setActiveBranch] = useState<BranchInterface>();

  const { setCookie, getCookie } = appCookies();

  const { setIsLoading } = useContext(AuthContext);

  const getDiffDate = useCallback(
    ({ date }: { date: Date }) => {
      let diffTextString = '';
      const diffMonths = differenceInMonths(TODAY, date);
      const diffDays = differenceInDays(TODAY, date);
      const diffHours = differenceInHours(TODAY, date);
      const diffMinutes = differenceInMinutes(TODAY, date);
      if (diffMonths <= 1) {
        if (!diffDays && !diffHours && diffMinutes <= 1) {
          diffTextString = t('common:notification.minuteAgoLabel');
        } else if (!diffDays && !diffHours && diffMinutes > 1) {
          diffTextString = `${diffMinutes} ${t('common:notification.minutesAgoLabel')}`;
        } else if (!diffDays && diffHours === 1) {
          diffTextString = t('common:notification.hourAgoLabel');
        } else if (!diffDays && diffHours > 1 && diffHours < 24) {
          diffTextString = `${diffHours} ${t('common:notification.hoursAgoLabel')}`;
        } else if (diffDays > 7) {
          diffTextString = dateFormatter(date, 'dd MMMM yyyy', {
            locale: i18n.language as LanguageType
          });
        } else if (diffDays === 1) {
          diffTextString = t('common:notification.dayAgoLabel');
        } else {
          diffTextString = `${diffDays} ${t('common:notification.daysAgoLabel')}`;
        }
      } else {
        diffTextString = `${diffMonths} ${t('common:notification.monthAgoLabel')}`;
      }
      return diffTextString;
    },
    [i18n.language, t]
  );

  useEffect(() => {
    const notificationStorage = localStorageService.getNotification('notification');
    if (notificationStorage !== '0' && activeCompany && userAuth && !isShowNotification) {
      setTimeout(() => {
        fetchCounter();
        setIsShowNotification(true);
      }, 100);
    }
  }, [activeCompany, fetchCounter, isShowNotification, setIsShowNotification, userAuth]);

  useEffect(() => {
    syncRefreshToken().finally(() => {
      setIsLoading(false);
    });
  }, [setIsLoading]);

  useClickOutside({
    ref: companyBranchSwitcherRef,
    cb: () => {
      setIsShowBranch(false);
    }
  });

  useClickOutside({
    ref: userRefElm,
    cb: () => {
      setIsShowAccount(false);
    }
  });

  useClickOutside({
    ref: notificationRefElm,
    cb: () => {
      setIsShowPopupNotification(false);
      setIsModify(false);
      setActiveTab('all');
      setCurrentPage(1);
    }
  });

  const handleChangeLanguage = async (to: LanguageType) => {
    setIsLoading(true);
    await postChangeLanguage({ lang: to });

    await syncRefreshToken().finally(() => {
      changeLanguage(to);
      setIsLoading(false);
    });
  };

  const [userData, setUserData] = useState<NavbarUserDropdown>({
    ...initialUserData,
    isHoverable: true,
    onClickLogout: logout
  });

  const switchCompanyEvent = () => {
    setIsShowBranch(false);
    setIsShowNotification(false);

    localStorageService.setNotification({
      key: 'notification',
      value: '0'
    });
    router.replace('/dashboard/onboardingcompany');
  };

  const { refetch: refetchBranch } = useQuery(
    ['/companies/location/', activeCompany],
    () =>
      getCompaniesLocation({
        companyId: activeCompany?.id,
        params: { perPage: 9999, sortBy: 'created_at', sort: 'asc' }
      }),
    {
      enabled: false,
      onSuccess: ({ data, error }) => {
        if (!error) {
          const listBranch = data.data.map((branchLocation: BranchLocationInterface) => {
            const branch: BranchInterface = {
              id: branchLocation.id,
              clientId: branchLocation.id,
              name: branchLocation.name
            };

            return branch;
          });

          setListBranch(listBranch);
        }
      }
    }
  );

  useEffect(() => {
    setIsShowBranch(false);
    setIsHideSidebar(isShouldHideSidebar(router.pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    setUserData({
      ...userData,
      email: userAuth?.email,
      name: userAuth?.name || '',
      avatarUrl: userAuth?.profilePicture ? `${userAuth?.profilePicture}?${new Date()}` : '',
      onClickEditProfile: () => null
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth?.name, userAuth?.email]);

  useEffect(() => {
    sessionStorage.setItem('lastPathname', router.asPath);
    if (sidebarActiveIdMapper[router.asPath]) {
      sessionStorage.setItem('referrer', router.asPath);
    }
  }, [router.route, router]);

  useEffect(() => {
    if (redirectUrl) {
      setActiveMenuIdSidebar(redirectUrl);
    } else {
      if (referrer && router.asPath === lastPathname) {
        setActiveMenuIdSidebar(sidebarActiveIdMapper[referrer]);
      } else {
        const currentPath = router.asPath.split('/').slice(0, 3).join('/');
        setActiveMenuIdSidebar(sidebarActiveIdMapper[currentPath]);
      }
    }
  }, [lastPathname, redirectUrl, referrer, router.asPath]);

  useEffect(() => {
    (async () => {
      const authInfoLocation = await getCookie({
        name: 'authInfoLocation'
      });

      const selectedBranch: BranchInterface = authInfoLocation && JSON.parse(authInfoLocation);

      if (listBranch && listBranch.length > 0) {
        let branchCompanies: BranchInterface[] = [];
        if (selectedBranch && !activeBranchId) {
          branchCompanies = listBranch.filter((branch) => branch.id === selectedBranch.id);
        } else if (activeBranchId) {
          branchCompanies = listBranch.filter((branch) => branch.id === activeBranchId);
        }
        const branchCompany = branchCompanies[0] ?? listBranch[0];

        setActiveBranch(branchCompany);
        await setCookie({
          name: 'authInfoLocation',
          value: JSON.stringify(branchCompany)
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listBranch]);

  useEffect(() => {
    if (activeCompany) {
      refetchBranch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  useEffect(() => {
    if (activeBranchId && activeCompany) {
      refetchBranch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBranchId, activeCompany]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (!isLoading && isShowNotification && notifData && notifData.length) {
      const newList: Array<NotificationGroupInterface> = [];
      notifData.map((notif: NotificationItemInterface, index: number) => {
        const groupName = notif.group === '7_days' ? t('common:current') : t('common:older');
        if (index === 0) {
          newList.push({
            index: index + 1,
            group: notif.group,
            title: groupName as string,
            isModify,
            selectedItem,
            list: [
              {
                id: notif.id,
                title: notif.title,
                isModify,
                isNewData: notif.status === 'SENT',
                isSelected: selectedItem.includes(notif.id),
                description: notif.content,
                time: getDiffDate({ date: new Date(notif.timestamp) }),
                url: notif.navigate ?? '',
                avatarUrl: notif.avatar ?? '',
                avatarText: getInitialNameForFallbackAvatar({ name: notif.title }),
                onClickItem: () => clickDetailItem(notif.id, notif.action),
                selectedItem
              }
            ]
          });
        } else {
          const groupIndex = newList.findIndex(
            (datas: NotificationGroupInterface) => datas.group === notif.group
          );
          if (groupIndex >= 0) {
            const currentList = newList[groupIndex].list;
            currentList.push({
              id: notif.id,
              title: notif.title,
              isModify,
              isNewData: notif.status === 'SENT',
              isSelected: selectedItem.includes(notif.id),
              description: notif.content,
              time: getDiffDate({ date: new Date(notif.timestamp) }),
              url: notif.navigate ?? '',
              avatarUrl: notif.avatar ?? '',
              avatarText: getInitialNameForFallbackAvatar({ name: notif.title }),
              onClickItem: () => clickDetailItem(notif.id, notif.action),
              selectedItem
            });
          } else {
            newList.push({
              index: index + 1,
              group: notif.group,
              title: groupName as string,
              isModify,
              selectedItem: [],
              list: [
                {
                  id: notif.id,
                  title: notif.title,
                  isModify,
                  isNewData: notif.status === 'SENT',
                  isSelected: selectedItem.includes(notif.id),
                  description: notif.content,
                  time: getDiffDate({ date: new Date(notif.timestamp) }),
                  url: notif.navigate ?? '',
                  avatarUrl: notif.avatar ?? '',
                  avatarText: getInitialNameForFallbackAvatar({ name: notif.title }),
                  onClickItem: () => clickDetailItem(notif.id, notif.action),
                  selectedItem
                }
              ]
            });
          }
        }
      });
      setNotifList(newList);
    }
  }, [
    notifData,
    isLoading,
    isShowNotification,
    isModify,
    selectedItem,
    clickDetailItem,
    t,
    getDiffDate
  ]);

  return (
    <>
      <LayoutStyled>
        <NavbarContainerStyled>
          <Navbar
            onClickLogo={() => {
              router.push(dashboardRoute);
              setRedirectUrl('');
              setActiveMenuIdSidebar(sidebarActiveIdMapper[dashboardRoute]);
            }}
            appLogoUrl={companyImgUrl}
            bgColor={process.env.BG_HEADER_BAR_COLOR ?? ColorBlue.darkBlue}
            languageSwitcher={{
              activeLanguage: currentLanguage,
              isOutsideLanguageSwitcher: true,
              hoverColor: process.env.BG_SWITCHER_HEADER_BAR_COLOR ?? ColorBlue.cornFlowerBlue,
              onClickEnglish: async () => {
                if (userAuth) {
                  setUserAuth({
                    ...userAuth,
                    lang: 'en'
                  });
                }
                await handleChangeLanguage('en');
              },
              onClickBahasa: async () => {
                if (userAuth) {
                  setUserAuth({
                    ...userAuth,
                    lang: 'id'
                  });
                }
                await handleChangeLanguage('id');
              }
            }}
            company={{
              onClickBranchSwitcher: () => setIsShowBranch((prev) => !prev),
              companyBranchRefElm: companyBranchSwitcherRef,
              isShowBranch: isShowBranch,
              onClickSwitchCompany:
                userAuth && userAuth.companies.length > 1 ? switchCompanyEvent : undefined,
              onClickBranch: async (value) => {
                await setCookie({
                  name: 'authInfoLocation',
                  value: JSON.stringify(value)
                });

                setActiveBranch(value);
                setActiveBranchId('');
                setIsShowBranch(false);
                router.reload();
              },
              companyName: activeCompany?.name ?? '',
              branchName: activeBranch?.name ?? '',
              listCompany: listBranch ?? [],
              logoUrl: activeCompany?.logo || '',
              hoverColor:
                userAuth?.companies && userAuth?.companies.length > 0
                  ? process.env.BG_SWITCHER_HEADER_BAR_COLOR ?? ColorBlue.cornFlowerBlue
                  : '',
              hoverItemColor: ColorLight.aliceBlue,
              isHoverable: false,
              activeColor: ColorLight.aliceBlue
            }}
            user={{
              ...userData,
              userRefElm,
              isShowAccount,
              onClickProfile: () => {
                setIsShowAccount((prev) => !prev);
              },
              onClickEditProfile: () => {
                if (!activeCompany && userAuth) {
                  setActiveCompany(userAuth?.companies[0]);
                  localStorageService.setActiveCompany({
                    key: 'active_company',
                    value: JSON.stringify(userAuth.companies[0])
                  });
                }
                router.push('/profile');
              },
              hoverColor: ColorRed.deepCarmine,
              hoverItemColor: ColorLight.whiteSmoke
            }}
            isHideCompany={isHideSidebar}
            isShowNotification={isShowNotification}
            notifications={{
              isLoading,
              isModify,
              isShowNotification: isShowPopupNotification,
              notificationRefElm: notificationRefElm,
              data: notifList,
              isEmpty,
              isShowUndoDelete,
              isShowMore: isViewMore,
              totalData: 0,
              totalPage: 0,
              totalUnread,
              locale: currentLanguage,
              tabActive: activeTab,
              selectedItem,
              isSelectAll,
              isDelete,
              isShowDeleteConfirmation: isDelete,
              onClickCancel: () => {
                setIsModify((prev) => !prev);
                setIsDelete(false);
                resetSelectedItem();
              },
              onClickCancelDelete: () => setIsDelete((prev) => !prev),
              onClickDeleteConfirmation: () => onDeleteConfirmation(),
              onClickDelete: () => setIsDelete((prev) => !prev),
              onClickMarkAsRead: () => onBulkMarkAsRead(),
              onClickModify: () => setIsModify((prev) => !prev),
              onClickRollbackDelete: () => onUndoDelete(),
              onClickSelectAll: () => {
                setIsSelectAll((prev) => !prev);
                fetchSelectedItem();
              },
              onClickTab: (value) => {
                setCurrentPage(1);
                setActiveTab(value as TabsType);
                resetSelectedItem();
              },
              onClickUnread: () => undefined,
              onClickViewMore: () => {
                setCurrentPage(currentPage + 1);
                setTimeout(() => {
                  fetchMoreData();
                }, 100);
              },
              onClickViewNotification: () => {
                fetchList();
                setIsShowPopupNotification((prev) => !prev);
              }
            }}
          />
        </NavbarContainerStyled>
        <MainStyled>
          {!isHideSidebar && (
            <SidebarContainerStyled>
              <Sidebar
                navigationConfig={getGuardedNavigation({
                  navConfig: navigationConfig,
                  userPermission: userAuth?.permissions ?? []
                })}
                onClickNav={(nav) => {
                  setActiveMenuIdSidebar(nav.id);
                  setRedirectUrl('');
                  if (nav.navLink) {
                    router.push(nav.navLink);
                  }
                }}
                activeId={activeMenuIdSidebar}
                appName={appData.appName}
                appVersion={appData.appVersion}
                activeParent="payrollCycle"
              />
            </SidebarContainerStyled>
          )}
          {children}
        </MainStyled>
      </LayoutStyled>
    </>
  );
}
