import {
  ParamsUnreadCountInterface,
  ResponseUnreadCountInterface
} from '@interface/NotificationInterface';
import { AxiosResponse } from 'axios';

import { axiosAuthInstance } from '../core/axios/axios.authConfig';

export const getNotificationUnreadCounter = async ({
  targetUserId,
  subscriber,
  company_id
}: ParamsUnreadCountInterface): Promise<ResponseUnreadCountInterface> => {
  return axiosAuthInstance
    .get('/notifications/unreadCount', {
      params: {
        targetUserId,
        subscriber,
        company_id
      }
    })
    .then((res: AxiosResponse<ResponseUnreadCountInterface>) => res.data)
    .catch((err) => err);
};
